import Link from "next/link";
import { FC } from "react";

import { AnimatedIcon } from "../../components/Button";
import { classNames } from "@frec-js/common-web";
import Image from "next/image";
import { FrecWebPath } from "../../utils/paths";

export const Banner100M: FC<{ className?: string }> = ({ className }) => {
  return (
    <Link
      href={FrecWebPath.hundredmillion}
      className={classNames(
        "w-full flex items-center justify-center gap-2 h-9 px-2 text-book12 sm:text-regular14 border-b !border-black bg-frecNeon group",
        className
      )}
    >
      <span className="text-nowrap">Frec reaches $100M in customer assets</span>
      <Image
        alt="rocket ship"
        src="/webstatic/svg/rocket-launch.svg"
        width={16}
        height={16}
      />
      <span className="flex items-center justify-center font-medium">
        Read the full story <AnimatedIcon />
      </span>
    </Link>
  );
};
